import styled from '@emotion/styled';
import { CommandeProduitType } from '@innedit/innedit-type';
import objectHash from 'object-hash';
import React, { FC } from 'react';

import Resume from '../Panier/Resume';
import RecapitulatifItem from './Item';

export const RecapitulatifSC = styled.div`
  box-sizing: border-box;

  .product-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0.75rem;

    figure {
      margin: 0 0.75rem 0 0;
      padding: 0.375rem 0;
      width: 60px;

      img {
        margin: 0;
        display: block;
        width: 100%;
      }
    }

    .label {
      flex: 1;
      margin-right: 0.75rem;
      font-size: 90%;
      display: flex;
      flex-direction: column;
    }

    h3 {
      margin: 0;
      font-size: inherit;
    }

    button {
      border: none;
      background: none;
      margin-right: 0;
      padding: 5px;
      color: #aeadb3;
      transition: color 0.2s ease;
      outline: none;
      cursor: pointer;
      font-size: 12px;
      &:before {
        content: '✕';
      }

      span {
        display: none;
      }
    }
  }
`;

const Recapitulatif: FC<{
  className?: string;
  deliveryMethod?: 'carrier' | 'store';
  livraison?: number;
  panier: CommandeProduitType[];
  subTotal?: number;
}> = ({ className, deliveryMethod, livraison, panier, subTotal }) => (
  <RecapitulatifSC className={className}>
    <h3 className="text-2xl text-center mb-6">Récapitulatif</h3>
    <div className="order-items">
      {panier.map(item => (
        <RecapitulatifItem key={objectHash(item)} item={item} />
      ))}
    </div>
    <Resume
      className="!w-full"
      deliveryAmount={livraison}
      deliveryMethod={deliveryMethod}
      panier={panier}
      produitsAmount={subTotal}
    />
  </RecapitulatifSC>
);

export default Recapitulatif;
