import { Form, SubmissionError, useDispatch } from '@innedit/formidable';
import { CommandeModel } from '@innedit/innedit-react';
import { CommandeType, DocumentType } from '@innedit/innedit-type';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElement } from '@stripe/stripe-js';
import { diff } from 'deep-object-diff';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import formDatas from '../../params/checkout.json';

interface FormCheckoutProps {
  commande: DocumentType<CommandeType>;
  formName: string;
  origin: string;
}

const FormCheckout: FC<FormCheckoutProps> = function ({
  commande,
  formName,
  origin,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [inProgress, setInProgress] = useState(false);
  const [pending, setPending] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [stripeError, setStripeError] = useState<string>();
  const [checkoutError, setCheckoutError] = useState<string>();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    let element: StripePaymentElement | null;
    if (elements) {
      element = elements.getElement('payment');

      if (element) {
        element.on('focus', () => {
          setStripeError(undefined);
        });
        element.on('change', ({ complete }) => {
          setPaymentComplete(complete);
        });
      }
    }

    return () => {
      if (element) {
        element.off('focus');
        element.off('change');
      }
    };
  }, [dispatch, elements, formName]);

  const handleOnChange = async (
    values: any,
    d: any,
    props: any,
    previousValues: any,
  ) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const diffValues: Partial<CommandeType> = diff(previousValues, values);
    // il faut enregistrer les différences
    delete diffValues.updatedAt;
    if (diffValues && Object.keys(diffValues).length > 0) {
      if (commande && Object.keys(previousValues).length > 0) {
        setInProgress(true);
        setTimeoutId(
          setTimeout(() => {
            // Mise à jour de la commande avec les modifications réalisées

            CommandeModel.update(commande.id, {
              ...values,
              language,
            });

            setInProgress(false);
          }, 500),
        );
      }
    }
  };

  const handleOnSubmit = async (values: CommandeType) => {
    if (!commande) {
      setPending(false);

      return new Promise((resolve, reject) => {
        reject(
          new SubmissionError({
            _error: "la commande n'existe pas",
          }),
        );
      });
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return new Promise((resolve, reject) => {
        reject(
          new SubmissionError({
            _error: 'order.creation.stripe.not.loaded',
          }),
        );
      });
    }

    if (pending) {
      return new Promise((resolve, reject) => {
        reject(
          new SubmissionError({
            _error: 'order.creation.inProgress',
          }),
        );
      });
    }

    setPending(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (!paymentComplete) {
      setStripeError("La saisie de la carte n'est pas complète");
    }

    let isConfirmed = false;
    try {
      isConfirmed = await CommandeModel.confirmByUser(commande.id, values);

      if (isConfirmed) {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url:
              origin + t('pages.commande.pathname', { id: commande.id }),
          },
          // redirect: 'if_required',
        });

        if (error) {
          setPending(false);
          setStripeError(error.message);
        }
      } else {
        setCheckoutError(
          "Il n'est pas possible de confirmer cette commande, veuillez nous contacter",
        );
      }
    } catch (e) {
      setPending(false);
      setCheckoutError((e as Error).message);
    }

    return setPending(false);
  };

  const produitsAmount = CommandeModel.calculateProduitsAmount(
    commande.produits,
  );

  const submitLabel = t('form.checkout.submit.label', {
    count: produitsAmount,
  });

  return (
    <Form
      datas={formDatas}
      footerProps={{
        className: 'justify-around mt-12',
      }}
      hideSubmitButton={'paid' === commande.status}
      initialValues={commande}
      isSubmissive={!pending && !inProgress}
      name={formName}
      onChange={handleOnChange}
      onSubmit={handleOnSubmit}
      removePristine
      submitProps={{
        className: 'bg-primary-700 hover:bg-primary-900 text-white-500',
        label: submitLabel,
        loading: pending,
      }}
    >
      {stripeError && <p className="text-danger-500">{stripeError}</p>}
      {checkoutError && <p>{checkoutError}</p>}
      {pending && (
        <div className="flex flex-col justify-center mt-2">
          <p className="text-center text-secondary-500 text-sm">
            {t('form.checkout.pending')}
          </p>
        </div>
      )}
    </Form>
  );
};

export default FormCheckout;
