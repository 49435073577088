import { CommandeProduitType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const RecapitulatifItem: FC<{ item: CommandeProduitType }> = ({ item }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const [produit, setProduit] = useState<any>();
  const { id, price, quantity } = item;

  useEffect(() => {
    let isMounted = true;

    const uri = process.env.GATSBY_SOURCE_INNEDIT_URI_API;
    const url = `${uri}/channels/${process.env.GATSBY_ID_CHANNEL}/produits/${id}`;
    window
      .fetch(url)
      .then(r => r.json())
      .then(documentSnapshot => {
        if (isMounted) {
          setProduit(documentSnapshot);
        }

        return isMounted;
      })
      .catch(console.error);

    return () => {
      isMounted = false;
    };
  }, [id]);

  if (!produit) {
    return null;
  }

  const { name, medias, sku, traductions } = produit;
  let photo;

  if (medias && medias.length > 0) {
    photo = `${medias[0].origin}/${medias[0].pathname}?w=120&h=120`;
  }

  return (
    <div key={photo} className="product-item">
      <figure>
        <img alt={name} src={photo} />
      </figure>
      <div className="label">
        <h3 className="name">
          {'en' === language && traductions?.en?.name
            ? traductions?.en?.name
            : name}
        </h3>
        <span className="italic text-sm">{sku}</span>
      </div>
      <span className="text-right">
        {quantity} x {price} €
      </span>
    </div>
  );
};

export default RecapitulatifItem;
