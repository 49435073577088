import styled from '@emotion/styled';
import { CommandeProduitType } from '@innedit/innedit-type';
import React, { FC } from 'react';

const CartResumeSC = styled.div`
  margin-top: 1.5rem;
  width: 300px;
  align-self: flex-end;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  > div {
    display: flex;

    .label {
      flex: 1;
      font-style: italic;
    }
  }

  .subtotal {
    border-top: 1px dashed #bbb7b1;
    padding-top: 0.75rem;
  }

  .total {
    //font-size: 120%;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px dashed #bbb7b1;

    .label {
      font-style: normal;
    }
  }
`;

const CartResume: FC<{
  className?: string;
  deliveryMethod?: 'carrier' | 'store';
  deliveryAmount?: number;
  panier: CommandeProduitType[];
  produitsAmount?: number;
}> = ({
  className,
  deliveryMethod,
  deliveryAmount,
  panier,
  produitsAmount,
}) => {
  const total =
    (produitsAmount || 0) +
    ('carrier' === deliveryMethod && deliveryAmount ? deliveryAmount : 0);

  return (
    <CartResumeSC className={className}>
      {'undefined' !== typeof deliveryAmount && (
        <>
          {deliveryMethod && (
            <div className="subtotal">
              <span className="label">
                Produit{panier.length > 1 ? 's' : ''}
              </span>
              <span className="price">{produitsAmount} €</span>
            </div>
          )}

          {deliveryMethod && (
            <div className="livraison">
              <span className="label">Livraison</span>
              {'carrier' === deliveryMethod && (
                <span className="price">
                  {deliveryAmount ? `${deliveryAmount} €` : 'Offerte'}
                </span>
              )}
              {'store' === deliveryMethod && (
                <span className="price">Retrait magasin</span>
              )}
            </div>
          )}
        </>
      )}

      <div className="total">
        <span className="label">Total</span>
        {'undefined' !== typeof total && (
          <span className="price">{total} €</span>
        )}
      </div>
    </CartResumeSC>
  );
};

export default CartResume;
