import { useSelector } from '@innedit/formidable';
import { CommandeModel } from '@innedit/innedit-react';
import { CommandeType, DocumentType } from '@innedit/innedit-type';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link, navigate, PageProps } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../components/Button';
import Content from '../components/Content';
import FormCheckout from '../components/Form/Checkout';
import Layout from '../components/Layout';
import Recapitulatif from '../components/Recapitulatif';
import { StateProps } from '../reducers';
import colors from '../styles/colors';
import control from '../styles/control';

const stripePromise = loadStripe(
  String(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY),
);

const appearance: { rules: any; theme: 'none'; variables: any } = {
  rules: {
    '.Input': {
      borderColor: control.border.color.base,
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    '.Input:focus': {
      boxShadow: '0 0 0.0625rem 0.1875rem #b6b2aa1f',
      outline: 'none',
    },
  },
  theme: 'none',
  variables: {
    borderRadius: '0.125rem',
    colorBackground: colors.light[200],
    fontFamily: 'sans-serif',
    fontSizeBase: '16px',
    fontWeightNormal: 400,
  },
};

const CheckoutPage: FC<PageProps & { id: string }> = function ({
  id,
  location: { origin, pathname },
}) {
  const { t } = useTranslation();
  const [commande, setCommande] = useState<DocumentType<CommandeType>>();
  const [unavailable, setUnavailable] = useState<boolean>();
  const formName = 'checkout';

  const { user } = useSelector((state: StateProps) => state);
  const authUser = user ? user.authUser : undefined;

  const [clientSecret, setClientSecret] = useState<string>();

  useEffect(() => {
    let isMounted = true;

    if (authUser?.uid && id) {
      CommandeModel.getByUserId(authUser.uid, id)
        .then(document => {
          if (isMounted) {
            setCommande(document);
          }

          return isMounted;
        })
        .catch(error => {
          console.error(error);
          // Impossible de charger la commande
          setUnavailable(true);
        });
    }

    return () => {
      isMounted = false;
    };
  }, [id, authUser?.uid]);

  const commandeJSON = JSON.stringify(commande);
  useEffect(() => {
    if (commande) {
      if ('paid' !== commande.status) {
        let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}/espaces/${process.env.GATSBY_ID_BOUTIQUE}`;
        url += `/payment_intents/create`;
        fetch(url, {
          body: JSON.stringify({ commande: commande.id }),
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
        })
          .then(res => res.json())
          .then(({ code, data, error, message }) => {
            if (error) {
              setUnavailable(true);
            } else {
              setClientSecret(data.client_secret);
            }

            return !error;
          })
          .catch(() => {
            setUnavailable(true);
          });
      } else {
        setUnavailable(true);
        navigate(t('pages.commande.pathname', { id: commande.id }));
      }
    }
  }, [commandeJSON]);

  const breadcrumbs = [
    {
      mobile: true,
      to: t('pages.inventaire.pathname'),
      value: 'pages.inventaire.title',
    },
    {
      mobile: true,
      to: t('pages.inventaire.pathname'),
      value: 'pages.panier.title',
    },
  ];

  if (unavailable) {
    return (
      <Layout
        breadcrumbs={breadcrumbs}
        breadcrumbsTitle="pages.checkout.title"
        pathname={pathname}
        showTitle
        title="pages.checkout.title"
      >
        <Content className="flex flex-col p-6 max-w-screen-md w-full mx-auto">
          <p className="text-center">{t('pages.commande.unavailable')}</p>
        </Content>
      </Layout>
    );
  }

  if (!commande || !clientSecret) {
    return (
      <Layout
        breadcrumbs={breadcrumbs}
        breadcrumbsTitle="pages.checkout.title"
        pathname={pathname}
        showTitle
        title="pages.checkout.title"
      >
        <Content className="flex flex-col p-6 max-w-screen-md w-full mx-auto">
          <p className="text-center">{t('loading')}</p>
        </Content>
      </Layout>
    );
  }

  const produitsAmount = CommandeModel.calculateProduitsAmount(
    commande.produits,
  );

  const options = {
    // passing the client secret obtained from the server
    appearance,
    clientSecret,
  };

  return (
    <Layout
      breadcrumbs={breadcrumbs}
      breadcrumbsTitle="pages.checkout.title"
      pathname={pathname}
      showTitle
      title="pages.checkout.title"
    >
      <Content className="flex flex-col p-6 max-w-screen-md w-full mx-auto">
        <Recapitulatif
          className="mb-12"
          deliveryMethod={commande.deliveryMethod}
          livraison={0}
          panier={commande.produits}
          subTotal={produitsAmount}
        />

        <div className="flex-1">
          <Elements options={options} stripe={stripePromise}>
            <FormCheckout
              commande={commande}
              formName={formName}
              origin={origin}
            />
          </Elements>

          <hr className="mt-12" />
          <div className="mt-12 flex justify-between">
            <div>
              <h2 className="mb-3 text-xl">Livraison</h2>
              <p className="mb-0">
                Vous souhaitez vous faire livrer votre commande ?
              </p>
              <p>N'hésitez pas à réaliser une demande de devis.</p>
            </div>

            <div className="flex justify-center self-center">
              <Button to={t('pages.contact.pathname')}>
                {t('pages.checkout.demande-devis-livraison')}
              </Button>
            </div>
          </div>

          <p className="text-center mt-20 mb-3">
            {t('pages.checkout.une-question')}
          </p>
          <p className="text-neutral-800 justify-center flex space-x-1 mt-3">
            <span>{t('pages.checkout.contactez-nous')}</span>
            <Link
              className="text-neutral-500 underline"
              to={t('pages.contact.pathname')}
            >
              {t('pages.checkout.formulaire')}
            </Link>
          </p>
          <p className="text-center text-neutral-800">
            {t('pages.checkout.ou-telephone')}{' '}
            <span className="text-neutral-500">
              {t('pages.checkout.phone')}
            </span>
          </p>
        </div>
      </Content>
    </Layout>
  );
};

export default CheckoutPage;
